import { useState } from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { IMarketButtons } from "../../../utils/types";

interface IBaseCardProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  buttonList?: IMarketButtons[];
  button?: IMarketButtons;
  setSelectedButton?: React.Dispatch<React.SetStateAction<IMarketButtons>>;
  timestamp?: string;
  iconImage?: string;
  iconBackground?: string;
}

const BaseCard = (props: IBaseCardProps) => {
  const { button, setSelectedButton, iconImage, iconBackground } = props;

  const updateButton = (value: IMarketButtons | undefined) => {
    if (value && setSelectedButton) setSelectedButton(value);
  };
  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-8px",
            marginBottom: "-8px",
          }}
        >
          <Box>
            <Typography variant="h5" component="div">
              {props.title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ mb: 1.5 }}
              color="text.secondary"
            >
              {props.subtitle}
            </Typography>
          </Box>
          {props.timestamp && (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              paddingTop="12px"
            >
              {props.timestamp}
            </Typography>
          )}
          {props.iconImage && (
            <Box
              sx={{
                backgroundColor: iconBackground,
                display: "flex",
                width: "48px",
                height: "48px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "12px",
              }}
            >
              <img src={iconImage} alt="icon" />
            </Box>
          )}

          {props.buttonList && props.buttonList.length > 0 && (
            <Box
              sx={{
                width: "357px",
                display: "flex",
                height: "40px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                onClick={() =>
                  updateButton(props.buttonList && props.buttonList[0])
                }
                sx={{
                  backgroundColor:
                    button && props.buttonList[0].name === button.name
                      ? "#003D81"
                      : "transparent",
                  color: "white",
                  width: "120px",
                  height: "40px",
                  padding: "10px, 12px",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderTopLeftRadius: 50,
                  borderBottomLeftRadius: 50,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                {button && props.buttonList[0].name === button.name ? (
                  <CheckIcon />
                ) : null}
                {props.buttonList[0].name}
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  updateButton(props.buttonList && props.buttonList[1])
                }
                sx={{
                  backgroundColor:
                    button && props.buttonList[1].name === button.name
                      ? "#003D81"
                      : "transparent",
                  color: "white",
                  width: "120px",
                  height: "40px",
                  padding: "10px, 12px",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderRadius: 0,
                }}
              >
                {button && props.buttonList[1].name === button.name ? (
                  <CheckIcon />
                ) : null}
                {props.buttonList[1].name}
              </Button>
              <Button
                variant="outlined"
                onClick={() =>
                  updateButton(props.buttonList && props.buttonList[2])
                }
                sx={{
                  backgroundColor:
                    button && props.buttonList[2].name === button.name
                      ? "#003D81"
                      : "transparent",
                  color: "white",
                  width: "120px",
                  height: "40px",
                  padding: "10px, 12px",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 50,
                  borderBottomRightRadius: 50,
                }}
              >
                {button && props.buttonList[2].name === button.name ? (
                  <CheckIcon />
                ) : null}
                {props.buttonList[2].name}
              </Button>
            </Box>
          )}
        </Box>

        {props.children}
      </CardContent>
    </Card>
  );
};
export default BaseCard;
