import { createContext, useState } from "react";
import { signinWithMS } from "../utils/firebase";

interface IAuthContext {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider = ({ children }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async () => {
    setIsLoggingIn(true);

    try {
      //   if (process.env.NODE_ENV === "development") {
      //     setIsLoggedIn(true);
      //     setIsLoggingIn(false);
      //     return;
      //   } else {
      //     await microsoftSignIn();
      //   }

      await microsoftSignIn();
      setIsLoggedIn(true);
      setIsLoggingIn(false);
    } catch (error) {
      console.log(error);
    }
  };

  const microsoftSignIn = async () => {
    try {
      const signedInUser = await signinWithMS();
      if (signedInUser === undefined) {
        throw new Error("User is undefined");
      }
      return;
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  const authContextValue: IAuthContext = {
    isLoggedIn: isLoggedIn,
    isLoggingIn,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
