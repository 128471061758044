import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Theme,
  Card,
} from "@mui/material";
import { MINUTE_MS } from "../../../../theme/globalVariables";
import { fontColors, graphColors } from "../../../../theme/palette";
import { formatTimestamp } from "../../../../utils/numer_utils";
import { get24HrAssets, getTotalAssets } from "../../../../api/AssetsAPI";
import { IAsset, IAssetLists } from "../../../../utils/types";
import AssetGraph from "./AssetGraph";

const AssetsCard = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [assets, setAssets] = useState<IAsset[]>();
  const [totalAssets, setTotalAssets] = useState<IAsset>();
  const [assetLists, setAssetLists] = useState<IAssetLists>();

  const getAssetData = async (): Promise<void> => {
    let tempAssets = await get24HrAssets();
    setAssets(tempAssets);
    let tempTotalAssets = await getTotalAssets();
    setTotalAssets(tempTotalAssets);
  };

  useEffect(() => {
    // Fetch from Firebase
    const interval = setInterval(() => {
      getAssetData();
    }, MINUTE_MS);

    if (assets) {
      splitDataByAsset(assets);
    }

    return () => clearInterval(interval);
  }, [assets]);

  const splitDataByAsset = (data: IAsset[]) => {
    let newAssetLists: IAssetLists = {
      barlowList: [],
      deerfootSolarList: [],
      empressList: [],
      fortyMileGranleaList: [],
      oldmanRiverList: [],
      timestampList: [],
    } as IAssetLists;

    for (let i = 0; i < data.length; i++) {
      newAssetLists.barlowList.push(data[i].barlow);
      newAssetLists.deerfootSolarList.push(data[i].deerfootSolar);
      newAssetLists.empressList.push(data[i].empress);
      newAssetLists.fortyMileGranleaList.push(data[i].fortyMileGranlea);
      newAssetLists.oldmanRiverList.push(data[i].oldmanRiver);

      const hours = String(data[i].timestamp.getHours()).padStart(2, "0");
      const mins = String(data[i].timestamp.getMinutes()).padStart(2, "0");
      newAssetLists.timestampList.push(`${hours}:${mins}`);
    }

    setAssetLists(newAssetLists);
  };

  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "16px",
          }}
        >
          <Typography sx={{}} variant="h4">
            Assets
          </Typography>
          {totalAssets && (
            <Typography variant="subtitle1" color="text.secondary">
              {formatTimestamp(totalAssets.timestamp)} • Shows data from the
              last 24 hours
            </Typography>
          )}
        </Box>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="stretch"
          rowSpacing={0}
          columnSpacing={0}
          style={{
            margin: 0,
            padding: 0,
            display: "flex",
          }}
        >
          {assetLists && (
            <>
              {totalAssets && (
                <>
                  <Grid item sm={12} md={4} lg={2.4} margin={0} padding={0}>
                    <AssetGraph
                      title={"FMG1"}
                      data={assetLists.fortyMileGranleaList}
                      currentValue={totalAssets?.fortyMileGranlea}
                      timestamps={assetLists.timestampList}
                      colour={graphColors.green_light}
                      fillColor={graphColors.green}
                      valueColor={fontColors.green}
                      yaxisMax={250}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} lg={2.4} margin={0} padding={0}>
                    <AssetGraph
                      title={"OMRH"}
                      data={assetLists.oldmanRiverList}
                      currentValue={totalAssets?.oldmanRiver}
                      timestamps={assetLists.timestampList}
                      colour={graphColors.blue_light}
                      fillColor={graphColors.blue}
                      valueColor={fontColors.blue}
                      yaxisMax={35}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} lg={2.4}>
                    <AssetGraph
                      title={"BLS1"}
                      data={assetLists.barlowList}
                      currentValue={totalAssets?.barlow}
                      timestamps={assetLists.timestampList}
                      colour={graphColors.yellow_light}
                      fillColor={graphColors.yellow}
                      valueColor={fontColors.yellow}
                      yaxisMax={30}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} lg={2.4}>
                    <AssetGraph
                      title={"DFT1"}
                      data={assetLists.deerfootSolarList}
                      currentValue={totalAssets?.deerfootSolar}
                      timestamps={assetLists.timestampList}
                      colour={graphColors.yellow_light}
                      fillColor={graphColors.yellow}
                      valueColor={fontColors.yellow}
                      yaxisMax={40}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} lg={2.4}>
                    <AssetGraph
                      title={"EMP1"}
                      data={assetLists.empressList}
                      currentValue={totalAssets?.empress}
                      timestamps={assetLists.timestampList}
                      colour={graphColors.yellow_light}
                      fillColor={graphColors.yellow}
                      valueColor={fontColors.yellow}
                      yaxisMax={40}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default AssetsCard;
