import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../utils/firebase";
import { IAsset } from "../utils/types";

export const getTotalAssets = async (): Promise<IAsset> => {
  const docRef = doc(db, "Assets", "current");
  const docSnap = await getDoc(docRef);
  let tempData = {} as IAsset;

  if (docSnap.exists()) {
    tempData = {
      barlow: docSnap.data().barlow,
      deerfootSolar: docSnap.data().deerfootSolar,
      empress: docSnap.data().empress,
      fortyMileGranlea: docSnap.data().fortyMileGranlea,
      oldmanRiver: docSnap.data().oldmanRiver,
      timestamp: new Date(docSnap.data().timestamp.seconds * 1000),
    } as IAsset;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
  return tempData;
};

export const get24HrAssets = async (): Promise<IAsset[]> => {
  // const docRef = doc(db, "AlbertaMarketSummary_7day");
  let assetList = [] as IAsset[];
  const querySnapshot = await getDocs(collection(db, "Assets_24hr"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let tempData = {
      barlow: doc.data().barlow,
      deerfootSolar: doc.data().deerfootSolar,
      empress: doc.data().empress,
      fortyMileGranlea: doc.data().fortyMileGranlea,
      oldmanRiver: doc.data().oldmanRiver,
      timestamp: new Date(doc.data().timestamp.seconds * 1000),
    } as IAsset;

    assetList.push(tempData);
  });
  return assetList;
};
