import { doc, getDoc } from "firebase/firestore";
import { IPrice } from "../utils/types";
import { db } from "../utils/firebase";

export const getPriceData = async (): Promise<IPrice> => {
  const docRef = doc(db, "Price", "current");
  const docSnap = await getDoc(docRef);
  let tempData = {} as IPrice;
  if (docSnap.exists()) {
    tempData = {
      systemMarginalPrice: docSnap.data().systemMarginalPrice,
      systemMarginalPriceTimestamp: new Date(
        docSnap.data().systemMarginalPriceTimestamp.seconds * 1000
      ),
      poolPrice: docSnap.data().poolPrice,
      poolPriceEndingHourTimestamp: new Date(
        docSnap.data().poolPriceEndingHourTimestamp.seconds * 1000
      ),
      poolPriceEndingHourNumber: docSnap.data().poolPriceEndingHourNumber,
    } as IPrice;
  }

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
  return tempData as IPrice;
};
