import { getDocs, collection, doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import {
  IMarketSummary,
  IMarketSummaryLists,
  IPriceData,
  IPriceOverTime,
} from "../utils/types";
import { db } from "../utils/firebase";
import { hr24Clock } from "../utils/numer_utils";

export const getMarketSummary = async (
  collectionName: string
): Promise<IMarketSummaryLists> => {
  let totalGenArray: number[] = [];
  let internalLoadArray: number[] = [];
  let timestamps: string[] = [];
  const querySnapshot = await getDocs(collection(db, collectionName));
  querySnapshot.forEach((doc) => {
    totalGenArray.push(doc.data().totalGen);
    internalLoadArray.push(doc.data().internalLoad);
    timestamps.push(hr24Clock(new Date(doc.data().timestamp.seconds * 1000)));
  });
  return {
    totalGen: totalGenArray,
    internalLoad: internalLoadArray,
    timestamp: timestamps,
  };
};

export const getPoolSummary = async (
  collectionName: string
): Promise<IPriceData> => {
  // let poolList = [] as IPriceOverTime[];
  let poolPriceArray: number[] = [];
  let timestamps: Date[] = [];
  const querySnapshot = await getDocs(collection(db, collectionName));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // let tempData = {
    //   poolPrice: doc.data().poolPrice,
    //   timestamp: new Date(
    //     doc.data().poolPriceEndingHourTimestamp.seconds * 1000
    //   ),
    // } as IPriceOverTime;

    poolPriceArray.push(doc.data().poolPrice);
    timestamps.push(
      new Date(doc.data().poolPriceEndingHourTimestamp.seconds * 1000)
    );
  });
  return { poolPriceArray, timestamps };
};

export const getTotalMarketSummary = async (): Promise<IMarketSummary> => {
  const docRef = doc(db, "AlbertaSummaryTotal", "current");
  const docSnap = await getDoc(docRef);

  let tempData = {} as IMarketSummary;

  if (docSnap.exists()) {
    tempData = {
      timestamp: new Date(docSnap.data().timestamp.seconds * 1000),
      totalGen: docSnap.data().totalGen,
      internalLoad: docSnap.data().internalLoad,
      netActualInterchange: docSnap.data().netActualInterchange,
      poolPrice: docSnap.data().poolPrice,
    } as IMarketSummary;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
  return tempData;
};
