import { OAuthProvider, User, getAuth, signInWithPopup } from "@firebase/auth";
import { initializeApp } from "firebase/app";
// import firebase from "firebase/compat/app";
// Required for side-effects
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const devConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGE,
  appId: process.env.REACT_APP_APP_ID,
};

const prodConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY_PROD,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN_PROD,
  projectId: process.env.REACT_APP_FB_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_FB_STORAGE_PROD,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGE_PROD,
  appId: process.env.REACT_APP_APP_ID_PROD,
};

const firebaseConfig =
  process.env.REACT_APP_FIREBASE_ENV === "prod" ? prodConfig : devConfig;

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const msProvider = new OAuthProvider("microsoft.com");
msProvider.setCustomParameters({
  tenant: process.env.REACT_APP_MS_TENANT!,
});

const signinWithMS = async (): Promise<User | undefined> => {
  try {
    const userCred = await signInWithPopup(getAuth(firebaseApp), msProvider);
    const user = userCred.user;
    return user;
  } catch (error) {
    return undefined;
  }
};

export { signinWithMS, firebaseApp, db };
