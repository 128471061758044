import numeral from "numeral";

//Deprecated
export const formatForCurrency = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

type InputValue = string | number | null;

export const fNumber = (number: InputValue) => {
  return numeral(number).format();
};

export const fCurrency = (number: InputValue) => {
  const format = number ? numeral(number).format("$0,0.00") : "";

  return result(format, ".00");
};

export const fCurrencyNoDecimal = (number: InputValue) => {
  const format = number ? numeral(number).format("$0,0") : "";

  return result(format, "");
};

export const fPercent = (number: InputValue) => {
  const format = number ? numeral(Number(number) / 100).format("0.0%") : "";

  return result(format, ".0");
};

export const fShortenNumber = (number: InputValue) => {
  const format = number ? numeral(number).format("0.00a") : "";

  return result(format, ".00");
};

export const fData = (number: InputValue) => {
  const format = number ? numeral(number).format("0.0 b") : "";

  return result(format, ".0");
};

function result(format: string, key = ".00") {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, "") : format;
}
export const lowerCaseAllWordsExceptFirstLetters = (rawWord: string) =>
  rawWord.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );

export const formatTimestamp = (timestamp: Date): string => {
  // format is in: Tue Oct 10 2023 11:00:00 GMT-0700 (Pacific Daylight Time)
  let monthDay = timestamp.toLocaleString("default", {
    month: "short",
    day: "2-digit",
  });

  let hourMinute = timestamp.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  let formatedTime = `Last Updated: ${hourMinute} on ${monthDay} `;
  return formatedTime;
};

export const formatTimestampEndingHour = (
  timestamp: Date,
  endingHour: number
): string => {
  // Tue Oct 10 2023 11:00:00 GMT-0700 (Pacific Daylight Time)
  let monthDay = timestamp.toLocaleString("default", {
    month: "short",
    day: "2-digit",
  });
  let formatedTime = `Last Updated: Hour ${endingHour} on ${monthDay}`;
  return formatedTime;
};

export const hr24Clock = (timestamp: Date): string => {
  let formatedTime = timestamp.toLocaleTimeString("it-IT", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatedTime;
};
