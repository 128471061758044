import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth_context";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/logo.png";
import BACKGROUND from "../../assets/login_bg.png";

const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const isLoggingIn = authContext?.isLoggingIn ?? false;

  const handleLogin = () => {
    authContext?.login();
  };

  useEffect(() => {
    const isLoggedIn = authContext?.isLoggedIn ?? false;

    if (!isLoggedIn) {
      navigate("/login");
    } else if (isLoggedIn) {
      navigate("/");
    } else {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext?.isLoggedIn]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${BACKGROUND})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
        }}
      >
        <Card sx={{ width: "100%", maxWidth: "400px" }}>
          <CardContent>
            <img height={35} src={LOGO} alt="logo" />
            <Typography
              variant="h4"
              component="h1"
              paddingTop={2}
              paddingBottom={2}
              textAlign={"center"}
            >
              Welcome to ATCO Renewable Energy Dashboard
            </Typography>
            <Typography
              variant="caption"
              component="h1"
              gutterBottom
              textAlign={"center"}
            >
              Please sign in to contine
            </Typography>
            <Button variant="contained" fullWidth onClick={handleLogin}>
              {isLoggingIn ? <CircularProgress size={24} /> : "Sign in"}
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default LoginPage;
