import { IMarketButtons } from "../../../../utils/types";

export const defaultButtonList = [
  {
    name: "7 Day",
    selected: false,
    marketData: "AlbertaMarketSummary_7day",
    poolData: "PoolPrice_7day",
    tickNumber: 6,
  },
  {
    name: "30 Day",
    selected: false,
    marketData: "AlbertaMarketSummary_30day",
    poolData: "PoolPrice_30day",
    tickNumber: 14,
  },
  {
    name: "24 Hour",
    selected: true,
    marketData: "AlbertaMarketSummary_24hr",
    poolData: "PoolPrice_24hr",
    tickNumber: 11,
  },
] as IMarketButtons[];

export const defaultButton = {
  name: "24 Hour",
  selected: true,
  marketData: "AlbertaMarketSummary_24hr",
  poolData: "PoolPrice_24hr",
  tickNumber: 11,
};

export const defaultMarketSummaryList = {
  totalGen: [],
  internalLoad: [],
  timestamp: [],
};
