import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { IMarketGeneration, IMarketGenEntry } from "../utils/types";

export const getMarketGenData = async (): Promise<IMarketGeneration> => {
  const docRef = doc(db, "MarketGeneration", "current");
  const docSnap = await getDoc(docRef);
  let marketData = {} as IMarketGeneration;

  if (docSnap.exists()) {
    let tempData = {
      timestamp: new Date(docSnap.data().timestamp.seconds * 1000),
      coal: docSnap.data().coal,
      energyStorage: docSnap.data().energyStorage,
      gas: docSnap.data().gas,
      hydro: docSnap.data().hydro,
      other: docSnap.data().other,
      solar: docSnap.data().solar,
      wind: docSnap.data().wind,
    } as IMarketGeneration;
    marketData = tempData;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
  return marketData;
};

export const getMarketGenList = async (): Promise<IMarketGenEntry[]> => {
  // const docRef = doc(db, "AlbertaMarketSummary_7day");
  let marketList = [] as IMarketGenEntry[];
  const docRef = doc(db, "MarketGeneration", "tableData");
  const docSnap = await getDoc(docRef);

  docSnap.data()!.table.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let tempData = {
      currentGeneration: doc.aggregated_net_generation,
      generationType: doc.fuel_type,
      percentOfMaxCapacity: doc.maximum_capability,
      percentOfAlbertaTNG: Math.floor(doc.percentage * 100),
      capabilityPercentage: doc.capability_percentage,
    } as IMarketGenEntry;

    marketList.push(tempData);
  });
  return marketList;
};
