import { Box, Typography } from "@mui/material";

interface ITitledValueProps {
  title: string;
  formattedValue?: string;
  value?: number;
  prefixUnits?: string;
  suffixUnits?: string;
  subtitle?: string;
  titleColor?: string;
  valueColor?: string;
}
const TitledValue = (props: ITitledValueProps) => {
  return (
    <Box>
      <Typography variant="h6" color={props.titleColor ? props.titleColor : ""}>
        {props.title}
      </Typography>
      <Typography variant="h3" color={props.valueColor}>
        {props.prefixUnits}
        {props.value ? props.value : props.formattedValue}
        <Typography variant="subtitle1" component="span">
          {props.suffixUnits}
        </Typography>
      </Typography>
      <Typography color="text.secondary" variant="subtitle1">
        {props.subtitle}
      </Typography>
    </Box>
  );
};
export default TitledValue;
