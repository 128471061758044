import { doc, getDoc } from "firebase/firestore";
import { IRevenue } from "../utils/types";
import { db } from "../utils/firebase";

export const getPowerRevData = async (): Promise<IRevenue> => {
  const docRef = doc(db, "Revenue", "current");
  const docSnap = await getDoc(docRef);
  let tempData = {} as IRevenue;

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    tempData = {
      merchantRevenue: docSnap.data().merchantRevenue,
      contractedRevenue: docSnap.data().contractedRevenue,
      totalRevenue: docSnap.data().totalRevenue,
      timestamp: new Date(docSnap.data().timestamp.seconds * 1000),
    } as IRevenue;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }

  return tempData;
};
