import { Grid } from "@mui/material";
import BaseCard from "../BaseCard";
import TitledValue from "../../../../components/TitledValue";
import { useEffect, useState } from "react";
import { MINUTE_MS } from "../../../../theme/globalVariables";
import { fontColors, graphColors } from "../../../../theme/palette";
import {
  formatTimestamp,
  formatTimestampEndingHour,
} from "../../../../utils/numer_utils";
import { IPrice } from "../../../../utils/types";
import { getPriceData } from "../../../../api/PriceAPI";
import MonetizationOn from "../../../../assets/monetization_on.png";

const PriceCard = () => {
  const [priceData, setPriceData] = useState<IPrice>({
    systemMarginalPrice: 0,
    systemMarginalPriceTimestamp: new Date(),
    poolPrice: 0,
    poolPriceEndingHourTimestamp: new Date(),
    poolPriceEndingHourNumber: 0,
  } as IPrice);

  const getData = async (): Promise<void> => {
    setPriceData(await getPriceData());
  };
  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [priceData]);

  return (
    <BaseCard
      title="Price"
      iconImage={MonetizationOn}
      iconBackground={graphColors.yellow}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <TitledValue
            title="System Marginal Price"
            subtitle={formatTimestamp(priceData.systemMarginalPriceTimestamp)}
            value={priceData.systemMarginalPrice}
            valueColor={fontColors.yellow}
            prefixUnits="$"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TitledValue
            title="Pool Price"
            subtitle={formatTimestampEndingHour(
              priceData.systemMarginalPriceTimestamp,
              priceData.poolPriceEndingHourNumber
            )}
            value={priceData.poolPrice}
            valueColor={fontColors.yellow}
            prefixUnits="$"
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <TitledValue
            title="Trending Daily Price"
            subtitle={priceData!.poolPriceEndingHourTimestamp.toString()}
            value={124.76}
            prefixUnits="$"
          />
        </Grid> */}
      </Grid>
    </BaseCard>
  );
};

export default PriceCard;
