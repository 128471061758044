import { Box, Button } from "@mui/material";
import versionInfo from "../../../version.json";

const VersionButton = () => {
  return (
    <Box
      paddingTop={"8px"}
      paddingRight={"8px"}
      paddingBottom={"8px"}
      display={"flex"}
      width={"100%"}
      alignContent={"end"}
      justifyContent={"end"}
    >
      <Button variant={"text"}>{versionInfo.version}</Button>
    </Box>
  );
};
export default VersionButton;
