import { useContext } from "react";
import { AuthContext } from "../context/auth_context";
import { Navigate } from "react-router-dom";

const AuthenticatedRoute = ({ children }) => {
  const authContext = useContext(AuthContext);

  if (!authContext?.isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthenticatedRoute;
