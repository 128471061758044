import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import TitledValue from "../../../../components/TitledValue";
import { MINUTE_MS } from "../../../../theme/globalVariables";
import { fontColors, graphColors } from "../../../../theme/palette";
import {
  formatTimestamp,
  fCurrencyNoDecimal,
} from "../../../../utils/numer_utils";
import BaseCard from "../BaseCard";
import { IRevenue } from "../../../../utils/types";
import { getPowerRevData } from "../../../../api/PowerRevenueAPI";
// import TrendingUpImage from "../../assets/trending_up.png";
// import { ReactComponent as TrendingUpImage } from "../../assets/trending_up2.svg";
import TrendingUpIcon from "../../../../assets/trending_up.png";

const PowerRevenueCard = () => {
  const [revenue, setRevenue] = useState<IRevenue>();

  const getRevenueData = async (): Promise<void> => {
    const revenueData = await getPowerRevData();
    if (revenueData !== undefined) {
      setRevenue(revenueData);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Setup Fetch to Firebase to monitor for data updates
      getRevenueData();
    }, MINUTE_MS);

    let revenueSeed = getPowerRevData();
    return () => clearInterval(interval);
  }, [revenue]);

  return (
    <>
      {revenue && (
        <BaseCard
          title="Power Revenue (YTD)"
          subtitle={formatTimestamp(revenue.timestamp)}
          iconImage={TrendingUpIcon}
          iconBackground={graphColors.blue}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4}>
              <TitledValue
                title="Merchant Revenue"
                formattedValue={fCurrencyNoDecimal(
                  revenue.merchantRevenue ?? 0
                )}
                valueColor={fontColors.blue}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitledValue
                title="Contracted Revenue"
                formattedValue={fCurrencyNoDecimal(
                  revenue.contractedRevenue ?? 0
                )}
                valueColor={fontColors.blue}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitledValue
                title="Total Revenue"
                formattedValue={fCurrencyNoDecimal(revenue.totalRevenue ?? 0)}
                valueColor={fontColors.blue}
              />
            </Grid>
          </Grid>
        </BaseCard>
      )}
    </>
  );
};

export default PowerRevenueCard;
