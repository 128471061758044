import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  makeStyles,
  createStyles,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import BaseCard from "../BaseCard";
import { MINUTE_MS } from "../../../../theme/globalVariables";
import {
  formatTimestamp,
  lowerCaseAllWordsExceptFirstLetters,
} from "../../../../utils/numer_utils";
import {
  getMarketGenData,
  getMarketGenList,
} from "../../../../api/MarketGenByTypeAPI";
import { IMarketGenEntry, IMarketGeneration } from "../../../../utils/types";

const MarketGenByTypeCard = () => {
  const COLOUR_WIND = "#032729";
  const COLOUR_SOLAR = "#493300";
  const COLOUR_HYDRO = "#001A37";

  const [marketEntries, setMarketEntries] = useState<IMarketGenEntry[]>([]);
  const [marketGen, setMarketGen] = useState<IMarketGeneration>();

  const getMarketData = async (): Promise<void> => {
    const marketData = await getMarketGenData();
    const marketListData = await getMarketGenList();
    setMarketGen(marketData);
    let sortedMarketListData = marketListData.sort((p1, p2) =>
      p1.currentGeneration < p2.currentGeneration
        ? 1
        : p1.currentGeneration > p2.currentGeneration
        ? -1
        : 0
    );
    setMarketEntries(sortedMarketListData);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getMarketData();
      return () => clearInterval(interval);
    }, MINUTE_MS);
  }, [marketEntries]);

  const getRowColour = (generationType: string): string => {
    if (generationType === "WIND") return COLOUR_WIND;
    if (generationType === "SOLAR") return COLOUR_SOLAR;
    if (generationType === "HYDRO") return COLOUR_HYDRO;
    return "#272727";
  };

  return (
    <>
      {marketGen && (
        <BaseCard
          title="Market Generation By Type"
          timestamp={formatTimestamp(marketGen.timestamp)}
        >
          <Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  backgroundColor: "transparent",
                  textAlign: "end",
                }}
              >
                <Box sx={{ width: "28%", textAlign: "start" }}>
                  <Typography color="white" variant="subtitle2">
                    Generation Type
                  </Typography>
                </Box>
                <Box sx={{ width: "24%" }}>
                  <Typography color="white" variant="subtitle2">
                    Current Generation
                  </Typography>
                </Box>
                <Box sx={{ width: "24%" }}>
                  <Typography color="white" variant="subtitle2">
                    % Of Alberta TNG
                  </Typography>
                </Box>
                <Box sx={{ width: "24%" }}>
                  <Typography color="white" variant="subtitle2">
                    % Of max Capacity
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ borderRadius: "16px", overflow: "hidden" }}>
              {marketEntries.map((entry) => (
                <Box
                  key={entry.generationType}
                  sx={{
                    display: "flex",
                    backgroundColor: getRowColour(entry.generationType),
                    border: "2px solid #121212",
                    radius: "8px",
                    overFlow: "hidden",
                    height: "48px",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    width: "100%",
                    textAlign: "end",
                  }}
                >
                  <Box sx={{ width: "28%" }}>
                    <Typography variant="h2" sx={{ textAlign: "start" }}>
                      {lowerCaseAllWordsExceptFirstLetters(
                        entry.generationType
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "24%" }} key={"currentGeneration"}>
                    <Typography variant="h2">
                      {entry.currentGeneration} MW
                    </Typography>
                  </Box>
                  <Box sx={{ width: "24%" }}>
                    <Typography variant="h2">
                      {entry.percentOfAlbertaTNG}%{" "}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "24%" }}>
                    <Typography variant="h2">
                      {Math.trunc(entry.capabilityPercentage * 100)}%
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </BaseCard>
      )}
    </>
  );
};

export default MarketGenByTypeCard;
