import { Box } from "@mui/material";
import TitledValue from "../../../../components/TitledValue";
import { useEffect, useState } from "react";
import Chart, { useChart } from "../../../../components/chart";
import { MINUTE_MS } from "../../../../theme/globalVariables";
import { fontColors } from "../../../../theme/palette";
import { formatTimestamp } from "../../../../utils/numer_utils";
import {
  IMarketButtons,
  IMarketSummary,
  IMarketSummaryLists,
  IPriceData,
} from "../../../../utils/types";
import {
  defaultButton,
  defaultButtonList,
  defaultMarketSummaryList,
} from "./DefaultData";
import {
  getMarketSummary,
  getPoolSummary,
  getTotalMarketSummary,
} from "../../../../api/MarketSummaryAPI";
import BaseCard from "../BaseCard";

const MarketSummaryCard = () => {
  const [poolPrices, setPoolPrices] = useState<number[]>([]);
  const [summary, setSummary] = useState<IMarketSummary>();
  const [marketData, setMarketData] = useState<IMarketSummaryLists>(
    defaultMarketSummaryList
  );
  const [poolData, setPoolData] = useState<IPriceData>();
  const [xTicks, setXTicks] = useState<number>(11);
  const [selectedButton, setSelectedButton] =
    useState<IMarketButtons>(defaultButton);

  const getMarketData = async (): Promise<void> => {
    const marketSummaryData = await getMarketSummary(selectedButton.marketData);
    setMarketData(marketSummaryData);
    const poolPriceData = await getPoolSummary(selectedButton.poolData);
    setPoolData(poolPriceData);
    setXTicks(selectedButton.tickNumber);

    let summaryTotal = (await getTotalMarketSummary()) as IMarketSummary;
    if (poolPriceData) {
      setPoolPrices(poolPriceData.poolPriceArray);
    }

    setSummary(summaryTotal);
  };

  useEffect(() => {
    // Fetch data
    const interval = setInterval(() => {
      getMarketData();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [marketData, selectedButton]);

  const series = [
    {
      name: "Total Generation",
      type: "bar",
      data: marketData?.totalGen,
      yAxisIndex: 0,
    },
    {
      name: "Internal Load",
      type: "bar",
      data: marketData?.internalLoad,
      yAxisIndex: 1,
    },
    { name: "Pool Price", type: "line", data: poolPrices, yAxisIndex: 2 },
  ];

  const chartOptions = useChart({
    chart: {
      stacked: false,
    },
    colors: ["#3379C6", "#C94D21", fontColors.yellow],
    // stroke: {
    //   width: [5, 5, 5],
    //   curve: "smooth",
    // },

    legend: {
      show: false,
    },
    xaxis: {
      categories: marketData?.timestamp,
      tickAmount: xTicks,
      labels: {
        rotate: 0,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
        style: {
          colors: "white",
        },
      },
    },

    yaxis: [
      {
        seriesName: "Generation MW",
        opposite: false,
        show: true,
        min: 8500,
        max: 12000,
        tickAmount: 7,
        labels: {
          formatter: (value: number) => {
            return `${value.toFixed(0)}`;
          },
          style: {
            colors: "white",
          },
        },
        title: {
          text: "Generation MW",
          style: {
            color: "white",
          },
        },
      },
      {
        seriesName: "Generation MW",
        opposite: false,
        show: false,

        labels: {
          formatter: (value: number) => {
            return `$${value.toFixed(0)}`;
          },
          style: {
            colors: "white",
          },
        },
        title: {
          text: "Generation MW",
        },
      },
      {
        seriesName: "Pool Price $",
        opposite: true,
        show: true,
        labels: {
          formatter: (value: number) => {
            return `${value.toFixed(0)}`;
          },
          style: {
            colors: "white",
          },
        },
        title: {
          text: "Pool Price $",
          style: {
            color: fontColors.yellow,
          },
        },
      },
    ],
    tooltip: {
      x: {
        show: false,
      },
      marker: { show: false },
    },
  });

  return (
    <>
      {summary && (
        <BaseCard
          title="Alberta Market Summary"
          subtitle={formatTimestamp(summary.timestamp)}
          buttonList={defaultButtonList}
          button={selectedButton}
          setSelectedButton={setSelectedButton}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <TitledValue
                title="Alberta Total Net Generation"
                titleColor={fontColors.blue}
                formattedValue={`${summary.totalGen}`}
                suffixUnits="  MW"
                valueColor={fontColors.blue}
              />
            </Box>
            <Box>
              <TitledValue
                title="Alberta Internal Load (AIL)"
                titleColor={fontColors.orange}
                formattedValue={`${summary.internalLoad}`}
                suffixUnits="  MW"
                valueColor={fontColors.orange}
              />
            </Box>
            <Box>
              <TitledValue
                title="Net Actual Interchange"
                formattedValue={`${summary.netActualInterchange}`}
                suffixUnits="  MW"
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginTop: "-16px",
              marginBottom: "-24px",
            }}
          >
            <Chart
              dir="ltr"
              series={series}
              options={chartOptions}
              height={272}
            />
          </Box>
        </BaseCard>
      )}
    </>
  );
};

export default MarketSummaryCard;
