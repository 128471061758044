import { Box, Stack, Typography } from "@mui/material";
import Chart, { useChart } from "../../../../components/chart";
import { IAssetGraph } from "../../../../utils/types";

const AssetGraph = (props: IAssetGraph) => {
  const series = [{ name: "MW Reading", data: props.data, type: "area" }];
  const chartOptions = useChart({
    chart: {
      stacked: false,
    },
    colors: [props.colour ?? "inherit"],
    fill: {
      type: "gradient",
      colors: [props.fillColor ?? "inherit"],
      gradient: {
        opacityFrom: 0.7,
        opacityTo: 0.9,
      },
    },

    legend: {
      show: false,
    },
    xaxis: {
      categories: props.timestamps,
      tickAmount: 4,

      labels: {
        rotate: 0,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
    },

    stroke: {
      curve: "smooth",
    },
    //TODO: Check if you need to hide/show yAxis
    yaxis: [
      {
        seriesName: "Generation MW",
        opposite: false,
        show: false,
        tickAmount: 4,
        max: props.yaxisMax ? props.yaxisMax : undefined,
        labels: {
          formatter: (value: number) => {
            return `${value.toFixed(0)} MW`;
          },
        },
      },
    ],
    tooltip: {
      x: {
        show: false,
      },
      marker: { show: false },
    },
  });

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: "0px", paddingLeft: "16px", paddingRight: "24px" }}
      >
        <Typography variant="h3">{props.title}</Typography>
        <Stack sx={{ direction: "row" }}>
          <Typography variant="h3" color={props.valueColor || "white"}>
            {props.currentValue}
            <Typography variant="subtitle1" component="span">
              {" "}
              MW
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          marginLeft: "20px",
          marginBottom: "-16px",
        }}
      >
        {props.yaxisMax} MW
      </Typography>
      <Box sx={{ marginLeft: "8px" }}>
        <Chart dir="ltr" series={series} options={chartOptions} height={212} />
      </Box>
    </Box>
  );
};
export default AssetGraph;
