import { Grid, useMediaQuery, Theme } from "@mui/material";
import VersionButton from "./components/VersionButton";
import AssetsCard from "./components/assetsCard/AssetsCard";
import MarketGenByTypeCard from "./components/marketGenByType/MarketGenByTypeCard";
import MarketSummaryCard from "./components/marketSummaryCard/MarketSummaryCard";
import PowerRevenueCard from "./components/powerRevenueCard/PowerRevenueCard";
import PriceCard from "./components/priceCard/PriceCard";

const DashboardPage = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        flexWrap={"wrap"}
        paddingTop={2}
        paddingLeft={2}
        paddingRight={2}
        rowGap={0}
        style={isDesktop ? { height: "100vh" } : {}}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          margin={0}
          // padding={0}
        >
          <PowerRevenueCard />
        </Grid>
        <Grid item xs={12} md={12} lg={6} margin={0} padding={0}>
          <PriceCard />
        </Grid>
        <Grid item xs={12} margin={0} padding={0}>
          <AssetsCard />
        </Grid>
        <Grid item xs={12} md={12} lg={7} margin={0} padding={0}>
          <MarketSummaryCard />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={5}
          margin={0}
          padding={0}
          // height={"35vh"}
        >
          <MarketGenByTypeCard />
        </Grid>
        <Grid
          item
          xs={12}
          margin={0}
          padding={0}
          position="fixed"
          bottom="0"
          right="0"
        >
          <VersionButton />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
